import { v1 as uuidv1 } from 'uuid'

const config = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
};

class Firebase {
    constructor(app) {
        app.initializeApp(config)

        /* Firebase APIs */
        this.firestore = app.firestore();

        this.firestore.enablePersistence()
            .catch(function (err) {
                if (err.code == 'failed-precondition') {
                    // Multiple tabs open, persistence can only be enabled
                    // in one tab at a a time.
                    // ...
                    console.log(`failed-precondition`)
                } else if (err.code == 'unimplemented') {
                    // The current browser does not support all of the
                    // features required to enable persistence
                    // ...
                    console.log(`unimplemented`)
                }
            })

    }

    //*** API ***

    /* get list of videos */
    getVideosList = () => {
        this.firestore.collection(`videos`).get()
            .then((videos) => {
                console.log("Read videos list successfully!")
                return videos
            })
            .catch((err) => {
                console.error("Error read videos list : ", err)
                return err
            })
    }



    /*** CRUD Video  ***/
    createVideo = async (video) => {
        let videoDocRef = this.firestore.collection(`videos`).doc()

        return (
            videoDocRef
                .set(video)
                .then(() => {
                    console.log("Video successfully created! :")
                    return videoDocRef.id
                })
                .catch((err) => {
                    console.error("Error video creating: ", err)
                    return err
                })
        )
    }

    getVideoRefById = (id) => {
        const videoRef = this.firestore.collection('videos').doc(id)

        return videoRef;
    }

    getVideoById = async (id) => {
        let video = null
        await this.firestore.collection(`videos`).doc(id)
            .get()
            .then((doc) => {
                console.log("Read video successfully!")
                video = doc.data()
            })
            .catch((err) => {
                console.error("Error read video : ", err)

            })
        return video
    }

    updateVideoById = async (id, data) => {
        await this.firestore.collection(`videos`).doc(id)
            .update(data)
            .then(() => {
                console.log("Video updated")
            })
            .catch((err) => {
                console.log("Error video updated : ", err)
            })
    }
    updateVideoStatus = async (video, status) => {
        await this.firestore.collection(`videos`).doc(video.id)
            .update(status)
            .then(() => {
                console.log("Video updated")
            })
            .catch((err) => {
                console.log("Error video updated : ", err)
            })
    }

    deleteVideo = (video) => {
        this.firestore.collection(`videos`).doc(video.id)
            .delete()
            .then(() => {
                console.log("Video successfully deleted!")
                return true
            })
            .catch((err) => {
                console.error("Error video deleted: ", err)
                return err
            })

        this.firestore.collection(`subtitles`).doc(video.subtitleId)
            .delete()
            .then(() => {
                console.log("Subtitles successfully deleted!")
                return true
            })
            .catch((err) => {
                console.error("Error Subtitles deleted: ", err)
                return err
            })
    }

    onSnapshotVideosList = async () => this.firestore.collection(`videos`);


    /*** CRUD Subtitles  ***/
    createSubtitle = async (video, startime, endTime) => {

        let uuid = uuidv1()
        console.log(uuid)
        let subtitle = {
            id: uuid,
            text: "",
            startTime: startime,
            endTime: endTime,
            approved: false,
            createdDate: Date.now(),
            subtitleCount: 0
        }

        await this.firestore.collection(`videos`)
            .doc(video.id)
            .collection(`subtitles`)
            .doc(uuid)
            .set(subtitle)
            .then(() => {
                console.log("Subtitl successfully created!")
            })
            .catch((err) => {
                console.err("Error create subtile: ", err)
            })

        uuid = null
    }

    createVideoAndSubtitles = async (video, subtitles) => {
        /* video doc*/

        let videoDocRef = await this.firestore.collection(`videos`).doc()

        console.log(videoDocRef.id)
        let suvtitlesDocRef = this.firestore.collection(`videos`)
            .doc(videoDocRef.id)
            .collection(`subtitles`)

        /* subtitle doc */
        let subtitleDocRef = this.firestore.collection(`subtitles`)
            .doc(video.subtitleId)
            .collection(`collection`)

        let batchSize = 499 //limit from firebase
        let operationCounter = 0
        let commitCounter = 0
        let batches = []
        for (const subtitle of subtitles) {
            if (operationCounter > batchSize) {
                operationCounter = 0
                batches[commitCounter] = this.firestore.batch()
            }

            batches[commitCounter] = this.firestore.batch()
            batches[commitCounter].set(suvtitlesDocRef.doc(subtitle.id), { ...subtitle });
            batches[commitCounter].set(subtitleDocRef.doc(subtitle.id), { createdDate: Date.now() });

            await batches[commitCounter].commit().then(function () {
                commitCounter++
                operationCounter++
            })
        }

        await videoDocRef.set(video)
    }

    updateSubtitleStatus = async (video, subtitle, status) => {
        await this.firestore
            .collection("videos")
            .doc(video.id)
            .collection("subtitles")
            .doc(subtitle.id)
            .update(status)
            .then(() => {
                console.log("Success")
            })
            .catch(() => {
                console.log("Error")
            })
    }

    updateSubtitle = async (video, subtitle) => {
        const subtitleTemp = {
            id: subtitle.id,
            text: subtitle.text,
            startTime: subtitle.startTime,
            endTime: subtitle.endTime,
            approved: false,
            createdDate: subtitle.createdDate,
        }
        await this.firestore
            .collection("videos")
            .doc(video.id)
            .collection("subtitles")
            .doc(subtitle.id)
            .update(subtitleTemp)
            .then(() => {
                console.log("Success")
            })
            .catch(() => {
                console.log("Error")
            })
    }

    updateSubtitleById = async (video, subtitle, update) => {
        await this.firestore
            .collection("videos")
            .doc(video.id)
            .collection("subtitles")
            .doc(subtitle.id)
            .update(update)
            .then(() => {
                console.log("Success")
            })
            .catch(() => {
                console.log("Error")
            })
    }

    deleteSubtitleById = async (video, subtitle) => {
        await this.firestore
            .collection("videos")
            .doc(video.id)
            .collection("subtitles")
            .doc(subtitle.id)
            .delete()
            .then(() => {
                console.log("Success")
            })
            .catch(() => {
                console.log("Error")
            })

        await this.firestore
            .collection("subtitles")
            .doc(video.subtitleId)
            .collection("collection")
            .doc(subtitle.id)
            .delete()
            .then(() => {
                console.log("Subtitle successfully deleted!")
            })
            .catch((error) => {
                console.err(error)
            })
    }

    getSubtitlesRefByVideoId = (videoId) => {
        const subtitleRef = this.firestore
            .collection("videos")
            .doc(videoId)
            .collection("subtitles")
            .orderBy('startTime', 'asc')

        return subtitleRef
    }

    getSubtitlesByVideoId = async (videoId) => {
        let subtitles = []
        await this.firestore
            .collection("videos")
            .doc(videoId)
            .collection("subtitles")
            .orderBy('startTime', 'asc')
            .get()
            .then((docs) => {
                docs.forEach((doc) => {
                    let subtitleTemp = { ...doc.data() }
                    subtitles.push(subtitleTemp)
                })
            })
            .catch((err) => {
                console.log(err)
            })

        return subtitles;
    }

    createSubtitleCollection = async (video, subtitle, generationUUID) => {
        const subtitleTemp = {
            id: subtitle.id,
            text: subtitle.text,
            startTime: subtitle.startTime,
            endTime: subtitle.endTime,
            approved: false,
            deleted: subtitle.text === "" ? true : false,
            createdDate: Date.now()
        }
        await this.firestore
            .collection("subtitles")
            .doc(video.subtitleId)
            .collection("collection")
            .doc(subtitle.id)
            .collection("subtitlesList")
            .doc(generationUUID)
            .set(subtitleTemp)
            .then(() => {
                console.log("Subtitle collection successfully created! :")
            })
            .catch((err) => {
                console.error("Error subtitle collection creating: ", err)
            })
    }

    getSubtitleFromCollectionById = async (video, subtitle, generationUUID) => {
        let subtitleTemp = null
        await this.firestore
            .collection("subtitles")
            .doc(video.subtitleId)
            .collection("collection")
            .doc(subtitle.id)
            .collection("subtitlesList")
            .doc(generationUUID)
            .get()
            .then((doc) => {
                console.log("Read subtitle from collection successfully!")
                subtitleTemp = doc.data()
            })
            .catch((err) => {
                console.error("Error subtitle from collection Read: ", err)
            })

        return subtitleTemp
    }

    getSubtitlesCollectionById = async (video, subtitle) => {
        let subtitlesCollection = []
        await this.firestore
            .collection("subtitles")
            .doc(video.subtitleId)
            .collection("collection")
            .doc(subtitle.id)
            .collection("subtitlesList")
            .where("deleted", "==", false)
            .orderBy("createdDate", "desc")
            .get()
            .then((docs) => {
                docs.forEach((doc) => {
                    let subtitleTemp = { ...doc.data() }
                    subtitlesCollection.push({ id: doc.id, subtitle: subtitleTemp })
                })
            })
            .catch((err) => {
                console.log(err)
            })

        return subtitlesCollection;

    }

    deleteSubtitleCollection = async (video) => {
        await this.firestore
            .collection("subtitles")
            .doc(video.subtitleId)
            .delete()
            .then(() => {
                console.log("Subtitle collection successfully deleted!")
            })
            .catch((error) => {
                console.err(error)
            })
    }

    deleteSubtitleInCollection = async (video, subtitle) => {
        console.log(subtitle)
        await this.firestore
            .collection("subtitles")
            .doc(video.subtitleId)
            .collection("collection")
            .doc(subtitle['subtitle']['id'])
            .collection("subtitlesList")
            .doc(subtitle['id'])
            .update({ deleted: true })
            .then(() => {
                console.log("Subtitle in collection successfully deleted!")
            })
            .catch((error) => {
                console.err(error)
            })
    }
}

let firebase;

function getFirebase(app, firestore) {
    if (typeof window !== `undefined`) {
        if (!firebase) {
            firebase = new Firebase(app, firestore)
        }
        return firebase
    }
    return null
}

export default getFirebase;